import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Auth/context';
import { useMe } from '../../../data/user';

function MainSection() {
    const { t } = useTranslation();
    const { isAuthorized } = useAuth();
    const { me } = useMe();
    return (
        <div id='home' className='relative'>
            {/* shadow */}
            <div className='bg-[#141418]/[0.2] w-full h-full pb-20 pt-24 top-0 bottom-0'>
                <div className="content relative z-20 h-full">
                    <div className='flex flex-col justify-center items-center h-full'>
                        <div className='text-center mb-20 mt-10 b z-20'>
                            <div className='relative'>
                                {/* <div className='border-main md:w-[25rem] md:h-[30rem] w-44 h-64 !border-[7px] lg:border-10 absolute -z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-[25deg]'></div> */}
                                <h1 className='font-semibold text-white md:text-7xl text-5xl'>{t('company_name')}</h1>
                                <p className='font-medium text-white md:text-5xl text-2xl !leading-normal mt-8 md:mt-14 w-96'>{t('onboarding_description')}</p>
                                {/* <p className='font-medium text-white md:text-5xl text-2xl mt-6'>{t('for_consultant')}</p> */}
                            </div>
                        </div>
                        <div className='pb-6 pt-4'>
                            <p className='font-medium text-white text-xs mt-14'>{t('service_summary')}</p>
                        </div>
                        {isAuthorized &&(
                        me?.type ==='client'&&
                        <div className="flex flex-col lg:flex-row gap-6 mx-2">
                            <Link to='/request-consultation' className="rounded md:py-3 font-semibold md:px-10 px-6 py-2 bg-main text-dark">{t('consulting_request')}</Link>
                            <Link to='/take-appointment' className="rounded md:py-3 font-semibold md:px-10 px-6 py-2 border-main border-s bg-transparent text-main">{t('book_appointment')}</Link>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainSection