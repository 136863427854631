import React from 'react'
import footerIcon from '../../../assets/images/logo.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Android, Apple } from '@mui/icons-material';
function FooterComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const scrollToSection = (id) => {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    };
  
    React.useEffect(() => {
      // Check if there is a hash in the URL, indicating a section to scroll to
      const hash = location.hash;
      if (hash) {
        const sectionId = hash.substring(1); // Remove the '#' character
        scrollToSection(sectionId);
      }
    }, [location]);
    const handleNavigation = (id) => {
        navigate("/");
        setTimeout(() => scrollToSection(id), 0); // Delay scrolling to allow for navigation
      };
    
    const { t } = useTranslation();
    return (
        <footer className='bg-white '>
            <div className=''>
                <div className='grid grid-rows-3 lg:grid-rows-1 md:grid-cols-12 gap-5 py-5 justify-items-stretch content-center'>
                    <div className='lg:col-span-3 flex justify-center  md:my-auto w-full'>
                        <img src={footerIcon} alt="" className="h-[50px] md:h-[80px] w-[30px] md:w-[50px]" />
                    </div>
                    <div className='lg:col-span-6 my-auto'>
                        <div className='grid grid-cols-1 justify-items-center content-center text-black'>
                            <div className='grid grid-cols-2 gap-3 text-center justify-items-center content-center text-xs'>
                                <Link to=''>
                                    <button onClick={() => handleNavigation('contact')}>{t("call_us")}</button>

                                </Link>
                                <Link to=''>
                                    <p className='md:ml-5 sm:ml-2'>{t('conditions')}</p>
                                </Link>
                                {/* <Link to=''>
                                    <p>{t('privacy')}</p>
                                </Link> */}
                            </div>
                            <div className='text-xs  mt-5 hidden lg:block text-center'>
                                <p>{t('all_rights_reserved')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-span-3 my-auto'>
                        <div className='grid grid-rows-2 gap-3 justify-center h-full text-center  px-0 '>
                        <a href={process.env.REACT_APP_ANDROID_LINK} target="_blank" className='flex cursor-pointer gap-3 w-full bg-main text-white rounded-xl items-center  sm:px-10 py-1' rel="noopener noreferrer">
                        تحميل التطبيق من جوجل بلاي<Android sx={{ fontSize: '25px' }} className='text-white' />
                            </a>
                            <a href={process.env.REACT_APP_IOS_LINK} target="_blank" className='flex cursor-pointer gap-3 w-full bg-main text-white rounded-xl items-center  sm:px-10 py-1' rel="noopener noreferrer">
                            تحميل التطبيق من ابل ستور<Apple sx={{ fontSize: '25px' }} className='text-white' />
                            </a>
                            {/* <Link to=''>
                                <TwitterIcon sx={{ fontSize: '25px' }} className='text-main' />
                            </Link> */}
                            
                        </div>
                        <div className='text-xs text-black mt-5 lg:hidden text-center'>
                                <p>{t('all_rights_reserved')}</p>
                            </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterComponent