import React, { useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMe } from "../../../data/user";
import { Logout, Notification, User } from "../../../Icons/Icons";
import { useAuth } from "../../Auth/context";

function Navbar() {
  const { t } = useTranslation();
  const { isAuthorized, unauthorize } = useAuth();
  const { me } = useMe();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navbarHandle = () => {
    setOpen(!open);
  };
  useEffect(()=>{
    setOpen(false)
  },[location])
  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // Check if there is a hash in the URL, indicating a section to scroll to
    const hash = location.hash;
    if (hash) {
      const sectionId = hash.substring(1); // Remove the '#' character
      scrollToSection(sectionId);
    }
  }, [location]);

  const handleNavigation = (id) => {
    navigate("/");
    setTimeout(() => scrollToSection(id), 0); // Delay scrolling to allow for navigation
  };

  const activeLink = 'border-main !border-b !border-t-0 !border-x-0';
  const hoverLink = 'hover:border-[#E4AC66] hover:!border-b hover:!border-t-0 hover:!border-x-0';
  const path = location.pathname.split('/')[1];

  return (
    <>
      <div className="bg-[#1C2A39]/0.01 navbar px-5 md:px-20 w-full py-2 fixed top-0 left-0 right-0 z-50">
        <div className="py-2 flex justify-between w-full">
          <div className="flex gap-20">
            <img src={logo} alt="" className="h-[50px] md:h-[80px] w-[30px] md:w-[50px]" />
            <div className="gap-12 justify-end items-center lg:flex hidden">
              <Link to='/' className={`relative inline-block ${path === '' ? activeLink : hoverLink} font-bold text-main`}>{t("home_page")}</Link>
              {me && <Link to='/my-requests' className={`${path === 'my-requests' ? activeLink : hoverLink} font-bold text-main`}>{t("my_requests")}</Link>}
              {me?.type === 'lawyer' && <Link to='/subscriptionplans' className={`${path === 'subscriptionplans' ? activeLink : hoverLink} font-bold text-main`}>{t("our_plans")}</Link>}
              <button onClick={() => handleNavigation('services')} className={`${path === '/our-service' ? activeLink : hoverLink} font-bold text-main`}>{t("our_services")}</button>
              <button onClick={() => handleNavigation('about')} className={`${path === '/about' ? activeLink : hoverLink} font-bold text-main`}>{t("who_we_are")}</button>
              <button onClick={() => handleNavigation('contact')} className={`${path === '/contact_us' ? activeLink : hoverLink} font-bold text-main`}>{t("contact_us")}</button>
              
            </div>
          </div>
          {!isAuthorized ? 
          <div className="flex-row items-center justify-between gap-6 lg:flex hidden">
            <Link to='/auth/signup' className="bg-main w-full border-2 font-medium border-main rounded-md text-nowrap px-10 py-4">
              {t("sign_up")}
            </Link>
            <Link to='/auth/login' className="text-main w-full border-2 font-medium border-main rounded-md text-nowrap px-10 py-4">
              {t("sign_in")}
            </Link>
          </div>
          :
          <div className="items-center gap-6 lg:flex hidden">
            <div className="flex items-center justify-between gap-4">
              <Link to='/notifications'>
                <Notification color={'#E4AC66'}/>
              </Link>
              <Link to='/profile'>
                <User color={'#E4AC66'} className='text-black'/>
              </Link>
              {me?.type === 'client' && 
              <Link to='/request-consultation' className="bg-main w-full border-2 font-medium border-main rounded-md text-nowrap px-10 py-4">
                {t("consulting_request")}
              </Link>}
              <button onClick={() => unauthorize()}>
                <Logout color={'#E4AC66'} className='text-black'/>
              </button>
            </div>
          </div>}
          <div className="my-auto mr-auto ml-5 col-span-3 lg:hidden flex gap-6  text-main">
            {isAuthorized && 
            <div className="items-center gap-6 lg:hidden flex">
              <div className="flex items-center justify-between gap-4">
                <Link to='/notifications'>
                  <Notification color={'#E4AC66'}/>
                </Link>
                <Link to='/profile'>
                  <User color={'#E4AC66'} className='text-black'/>
                </Link>
                {me?.type === 'client' && 
                <Link to='/request-consultation' className="bg-main w-full border-2 font-medium border-main rounded-md text-nowrap px-10 py-4">
                  {t("consulting_request")}
                </Link>}
                <button onClick={() => unauthorize()}>
                  <Logout color={'#E4AC66'} className='text-black'/>
                </button>
              </div>
            </div>
            }

            <button onClick={navbarHandle}>
              <MenuIcon sx={{ fontSize: "40px" }} />
            </button>
          </div>
        </div>
      </div>
      <div
        className={`lg:hidden block content top-20 md:top-28 fixed left-0 right-0 z-[99] bg-slate-600 p-3 rounded transition ease-in-out duration-700 transform ${
          !open ? "transform translate-x-[-100%]" : "transform translate-x-0"
        }`}
      >
        <div className="text- text-base text-center font-medium grid grid-cols-1 gap-5">
          <Link to='/' className={`relative inline-block`}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("home_page")}
            </p>
          </Link>
          {me && 
          <Link to='/my-requests' className={`relative inline-block`}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("my_requests")}
            </p>
          </Link>}
          {me?.type === 'lawyer' && 
          <Link to='/subscriptionplans' className={`relative inline-block`}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("our_plans")}
            </p>
          </Link>}
          <button onClick={() => handleNavigation('services')}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("our_services")}
            </p>
          </button>
          <button onClick={() => handleNavigation('about')}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("about_website")}
            </p>
          </button>
          <button onClick={() => handleNavigation('contact')}>
            <p className="bg-main w-full px-10 py-5 rounded-lg">
              {t("contact_us")}
            </p>
          </button>
          {!isAuthorized ? 
          <>
            <Link to='/auth/login'
              className="text-main w-full border-2 font-medium border-main rounded-md px-5 py-3"
            >
              {t("sign_in")}
            </Link>
            <Link to='/auth/signup'
              className="text- w-ful bg-main font-medium rounded-md px-5 py-3"
            >
              {t("sign_up")}
            </Link>
          </>: null}
        </div>
      </div>
    </>
  );
}

export default Navbar;
