import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './Pages/Auth/Login/Login';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { faIR } from '@mui/material/locale';
import AuthLayout from './Components/Layout/AuthLayout';
import HomeAuth from './Pages/Auth/Home/HomeAuth';
import Signup from './Pages/Auth/Signup/Signup';
import OTP from './Pages/Auth/OTP/OTP';
import Layout from './Components/Layout/Layout';
import SubscriptionPlans from './Pages/Lawyer/SubscriptionPlans/SubscriptionPlans';
import Profile from './Pages/Client/Profile/Profile';
import TabsLayout from './Components/Layout/TabsLayout';
import Wallet from './Pages/Lawyer/Wallet/Wallet';
import RequestConsultation from './Pages/Client/RequestConsultation/RequestConsultation';
import TakeAppointment from './Pages/Client/TakeAppointment/TakeAppointment';
import Notifications from './Pages/Lawyer/Notifications/Notifications';
import ConsultationDetails from './Pages/Shared/ConsultationDetails/ConsultationDetails';
import PrivateRoute from './Components/private-route';
import MyRequests from './Pages/Client/MyRequests/MyRequests';
import RecoveryAccount from './Pages/Auth/RecoveryAccount/RecoveryAccount';
import ResetPassword from './Pages/Auth/ResetPassword/ResetPassword';
import Lawyers from './Pages/Client/Lawyers/Lawyers';
import Lawyer from './Pages/Client/Lawyers/Lawyer';


function App() {
  const theme = useTheme(); //replace with your theme/createTheme

  return (
    <div style={{ direction:'rtl'}}>
    <ThemeProvider theme={createTheme({ ...theme, direction: 'rtl' }, faIR)}>
    <Router> 
      <Routes>
        <Route element={<AuthLayout />} >
          <Route path="/auth" element={<HomeAuth />} />
          <Route path="/auth/signup" element={<Signup />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/otp" element={<OTP />} />
          <Route path="/auth/recovery" element={<RecoveryAccount />} />
          <Route path="/auth/resetPassword" element={<ResetPassword />} />
        </Route>
        <Route element={<Layout />} >
          <Route path="/" element={<Home />} />
          <Route path="/lawyer/:id" element={<Lawyer />} />
          <Route element={<PrivateRoute/>}>
          <Route path="/subscriptionplans" element={<SubscriptionPlans />} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/lawyers" element={<Lawyers />} />
            <Route element={<TabsLayout />} >
              <Route path="/request-consultation" element={<RequestConsultation/>} />
              <Route path="/take-appointment" element={<TakeAppointment/>} />
            </Route>
            <Route path="/my-requests" element={<MyRequests/>} />
            <Route path="/my-request/:id" element={<ConsultationDetails />} />
          </Route>
          {/* client  */}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </ThemeProvider>
    </div>
  );
}

export default App;
