import React from 'react'
import Slider from 'react-slick'
import AboutWebSiteCard from '../AboutWebSiteCard'
import { useTranslation } from 'react-i18next'
import { useAboutApp } from '../../../data/vistor'
const min = (a, b) => a < b ? a : b
function getSettings(minCount, isInfinite = true, isDots = false) {
    return {
        dots: isDots,
        infinite: isInfinite,
        autoplay: true,
        speed: 500,
        slidesToShow:3,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <></>,
        prevArrow: <></>,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: min(minCount, 1),
                    slidesToScroll: 1,
                }
            },
        ]
    }
}
function AboutSection() {
    const {data } = useAboutApp()
    const { t } = useTranslation();
    return (
        <div id='about' className='bg-dark py-20 '>
            <div className='container mx-auto'>

                <h3 className={`text-4xl font-semibold text-white md:text-center text-right mb-20 md:mr-0 mr-3`}>{t('about_website')}</h3>
                {[...data ,...data]?.length ===1 ?
                <div >
                    <AboutWebSiteCard key={data[0]._id} isLast={true} title={data[0].title} description={data[0].description} />
                </div>:
                <Slider {...getSettings((data?.length))}>
                    {[...data ,...data].map((about , index) => (
                        <AboutWebSiteCard key={about._id} isLast={data?.length===index+1} title={about.title} description={about.description} />
                    ))}
                </Slider>
                }
            </div>
        </div>
    )
}

export default AboutSection